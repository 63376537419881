<template>
	<div>
		<v-card>
            <Breadcrumbs />
            <v-row class="pa-4">
                <v-col cols="3">
                    <!-- PK副标的搜索 -->
                    <v-text-field class="" v-model="search" outlined dense
                        append-icon="mdi-search" label="Search User" single-line hide-details>
                    </v-text-field>
                </v-col>

                <v-col cols="5"></v-col>
                
                <v-col cols="4" class="text-right">
                    <v-btn @click.stop="triggerDialog(1)" color="primary" class="mr-2">ADD USER</v-btn>
                    <v-btn @click="userHandler().load()" :loading="loading" color="blue-grey" class="mr-2">Refresh</v-btn>
                    <v-btn @click.stop="batchDialog()" :disabled="selectedUsers.length == 0" color="red">
                        UPDATE PERMISSION
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-data-table :search="search" dense
                        v-model="selectedUsers" :headers="headers" :items="listUser" class="elevation-1"
                        :single-select="false" show-select :items-per-page="itemsPerPage">

                        <template v-slot:item.is_active="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-switch :input-value="item.is_active" :loading="loading" dense small class="mt-0 pt-0" style="height: 20px;"
                                        @change="(event) => toggleStatus(event, item)"></v-switch>
                                </template>
                                <span>{{ item.is_active }}</span>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="triggerDialog(2, item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog.visible" :fullscreen="dialog.fullscreen"
                width="1000" :hide-overlay="true" :persistent="true">

                <v-card class="pt-0">
                    <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);" >
                        <v-toolbar-title class="text-h6 white--text pl-0">
                            {{ dialog.title }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn color="white" icon @click="dialog.fullscreen = !dialog.fullscreen">
                            <v-icon>mdi-arrow-expand</v-icon>
                        </v-btn>
                        <v-btn color="white" icon @click="dialog.visible = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>

                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="Email" :readonly="dialog.type == 2" v-model="dialog.email" hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="Password" v-model="dialog.password" hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="Username" v-model="dialog.username" hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="First Name" v-model="dialog.firstname" hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="Last Name" v-model="dialog.lastname" hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="4">
                                <v-text-field label="Role" v-model="dialog.role" hide-details="auto"></v-text-field>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="12" lg="12" xl="4" v-if="this.$store.state.brokerMap.length > 1">
                                <v-autocomplete label="Account Broker" v-model="dialog.brokerId" 
                                    :items="filterBroker" item-text="broker" item-value="broker_id" 
                                    auto-select-first chips clearable deletable-chips small-chips>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <p>Permission</p>
                                <JsonEditor ref="jsonEditor" v-model="dialog.permission" v-if="dialog.loadJson" />
                            </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialog.visible = false" :disabled="dialog.loading">Cancel</v-btn>
                        <v-btn color="green darken-1" v-if="dialog.type == 1"
                            text @click="userHandler().create()" :loading="loading">
                            <span>Add</span>
                        </v-btn>
                        <v-btn color="green darken-1" v-if="dialog.type == 2"
                            text @click="userHandler().update()" :loading="loading">
                            <span>Update</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="batch.visible" :fullscreen="batch.fullscreen"
                    width="1000" :hide-overlay="true" :persistent="true">

                    <v-card class="pt-0">

                        <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);" >

                            <v-toolbar-title class="text-h6 white--text pl-0">
                                {{ batch.title }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>

                            <v-btn color="white" icon @click="batch.fullscreen = !batch.fullscreen">
                                <v-icon>mdi-arrow-expand</v-icon>
                            </v-btn>
                            <v-btn color="white" icon @click="batch.visible = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-app-bar>

                        <v-card-actions>
                            <v-row>
                                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                                    <v-autocomplete label="Select Permission Role"
                                        v-model="selectedRole" :items="permissionList"
                                        auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <p>Permission</p>
                                    <JsonEditor ref="jsonEditor" v-if="batch.loadJson" v-model="batch.permission" />
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="batch.visible = false">Cancel</v-btn>
                            <v-btn color="green darken-1" text @click="batchUpdatePermission()" :loading="loading">
                                <span>Update</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

        </v-card>
    </div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import JsonEditor from "@components/ComponentJsonEditor";
import { mapState, mapActions } from "vuex";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";

export default {
    components: { Breadcrumbs, JsonEditor },
    mixins: [helper, snackbar],
    data () {
        return {
            search: "",
            btnLoading: false,
            itemsPerPage: 30,
            batch: {
                fullscreen: false,
                visible: false,
                title: "",
                role: "",
                permission: [],
                loadJson: false,
                loading: false
            },
            dialog: {
                id: -1,
                brokerId: -1,
                fullscreen: false,
                type: 0,
                visible: false,
                title: "",
                loading: false,
                email: "",
                password: "",
                username: "",
                firstname: "",
                lastname: "",
                permission: [],
                role: "Admin",
                loadJson: false,
                jti: "",
                isActive: null,
                loadingActive: false,
                loadingLogin: false
            },
            selectedRole: "",
            selectedUsers: [],
        };
    },
    computed: {
        ...mapState("UserManagement", ["listUser", "loading", "headers", "permissionData", "permissionList"]),
        filterBroker() {
            let brokers = this.$store.state.brokerMap;
            brokers[brokers.length] = { broker_id: -1, broker: "ALL", book: ["*"], bookExcept: [] };
            return brokers;
        }
    },
    watch: {
        /**
         * Display permission data according the users' selection
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        selectedRole(nv, ov) {
            const data = JSON.parse(localStorage.getItem("permissionData"));
            this.batch.permission = data.filter(item => item.role == nv).map(item => item.permission)[0];
        }
    },
    methods: {
        ...mapActions("UserManagement", ["userLoad", "userCreate", "validJson", "userUpdate", "userStatus", "permissionLoad", "permissionUpdate"]),
        /**
         * [triggerDialog description]
         * @param   {[type]}  type  [type description]
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        triggerDialog(type, data=null) {
            this.dialog.type = type;
            this.dialog.visible = true;

            if (type == 1) {
                this.dialog.title = "Add New User";
            }

            if (type == 2) {
                this.dialog.id = data.id;
                this.dialog.title = "Update Existing User";
                this.dialog.email = data.email;
                this.dialog.password = data.password;
                this.dialog.username = data.username;
                this.dialog.firstname = data.first_name;
                this.dialog.lastname = data.last_name;
                this.dialog.role = data.role;
                this.dialog.jti = data.jti;
                this.dialog.permission = JSON.parse(data.permission);
                this.dialog.loadJson = false;
                this.dialog.isActive = data.is_active;
                this.dialog.brokerId = data.broker_id;
            }

            setTimeout(() => {
                this.$nextTick(() => {
                    this.dialog.loadJson = true;
                });
            }, 500);
        },
        /**
         * Change user status
         * @param   {[type]}  event  [event description]
         * @param   {[type]}  item   [item description]
         * @return  {[type]}         [return description]
         */
        toggleStatus(event, item) {
            let params = item;
            params["is_active"] = event;
            this.userStatus(params);
        },
        /**
         * User handler that helps to call user api to load, create or update user
         * @return  {[type]}  [return description]
         */
        userHandler() {
            let self = this;
            return {
                // load user
                load() {
                    self.userLoad();
                },
                // create user
                create() {
                    self.userCreate(self.dialog);
                },
                // update user
                update() {
                    self.userUpdate(self.dialog);
                }
            }
        },
        /**
         * Open bath update dialog
         * @return {[type]} [description]
         */
        batchDialog() {
            this.batch.visible = [];
            this.batch.permission = [];
            this.batch.title = "Batch Permission Editor";
            this.batch.loadJson = false;

            // load permission on dialog open
            this.permissionLoad();

            setTimeout(() => {
                this.$nextTick(() => {
                    this.batch.loadJson = true;
                });
            }, 500);
        },
        /**
         * Batch Update permission
         * @return  {[type]}  [return description]
         */
        batchUpdatePermission() {
            let data = { batch: this.batch, selectedUsers: this.selectedUsers };
            this.permissionUpdate(data);
        },
    },
    mounted () {
        this.userLoad();
    }
};
</script>

